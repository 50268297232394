import { useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import React from "react";
import Input from "../../components/Input";
import NumberInput from "../../components/NumberInput";
import ISOStringDateTimePicker from "../../components/ISOStringDateTimePicker";
import Select from "../../components/Select";

export const daysOfWeek = [
  { displayText: "None", value: null, key: "None" },
  { displayText: "Sunday", value: 0, key: "Sunday" },
  { displayText: "Monday", value: 1, key: "Monday" },
  { displayText: "Tuesday", value: 2, key: "Tuesday" },
  { displayText: "Wednesday", value: 3, key: "Wednesday" },
  { displayText: "Thursday", value: 4, key: "Thursday" },
  { displayText: "Friday", value: 5, key: "Friday" },
  { displayText: "Saturday", value: 6, key: "Saturday" },
];

const Boosters = ({ tournament, state, setState }) => {
  const handleAdd = useCallback(() => {
    var newBooster = {
      multiplier: 1,
      id: 0,
      tournament_id: tournament.id,
    };

    setState((prev) => ({
      ...prev,
      boosters: [...prev.boosters, newBooster],
      hasUnsavedChanges: true,
    }));
  }, [setState, tournament?.id]);

  const handleDeletion = useCallback(
    // Delete booster from list if new
    // otherwise add IsDeleted flag
    (index) => {
      setState((prev) => ({
        ...prev,
        boosters: prev.boosters
          .map((booster, i) =>
            i === index
              ? booster.id === 0
                ? null
                : { ...booster, isDeleted: true }
              : booster
          )
          .filter(Boolean),
        hasUnsavedChanges: true,
      }));
    },
    [setState]
  );

  const handleEdit = useCallback(
    (e, index) => {
      setState((prevState) => ({
        ...prevState,
        boosters: prevState.boosters.map((booster, i) =>
          i === index
            ? { ...booster, [e.target.name]: e.target.value }
            : booster
        ),
        hasUnsavedChanges: true,
      }));
    },
    [setState]
  );

  const count = state.boosters.filter((x) => !x.isDeleted).length;

  return (
    <div className="boosters">
      <Row className="align-items-center mb-2">
        {count === 0 ? (
          <Col xs={6}>
            <div className="text-muted">There are no boosters</div>
          </Col>
        ) : (
          <Col xs={6}>
            <div>{count} boosters</div>
          </Col>
        )}
        <Col className="text-end">
          {!state.loading && !state.saving && state.editable && (
            <Button
              disabled={state.loading || state.saving || !state.editable}
              size="sm"
              variant="outline-primary"
              className="me-2"
              onClick={handleAdd}
            >
              Add
            </Button>
          )}

          <Button
            variant="outline-secondary"
            size="sm"
            disabled={state.loading || state.saving}
            onClick={() =>
              setState((prev) => ({ ...prev, refresh: !prev.refresh }))
            }
          >
            Refresh
          </Button>
        </Col>
      </Row>
      {state.loading || !tournament ? (
        <div colSpan="7">Loading...</div>
      ) : state.error ? (
        <div colSpan="7">Error: {state.error.message}</div>
      ) : (
        <>
          {state.boosters.map((booster, index) => {
            //
            // Set key for each booster
            // This is used to identify each booster in the list, internally
            booster.__key ??=
              booster.id > 0 ? `id-${booster.id}` : `timestamp-${Date.now()}`;

            return (
              !booster.isDeleted && (
                <Row key={booster.__key} className="mb-2">
                  <Col xs={2}>
                    <NumberInput
                      label="Multiplier"
                      name={"multiplier"}
                      value={booster.multiplier}
                      onChange={(e) => handleEdit(e, index)}
                      disabled={
                        state.loading || state.saving || !state.editable
                      }
                    />
                  </Col>
                  <Col xs={2}>
                    <Input
                      label="Name"
                      name={"name"}
                      value={booster.name}
                      onChange={(e) => handleEdit(e, index)}
                      disabled={
                        state.loading || state.saving || !state.editable
                      }
                    />
                  </Col>
                  {(() => {
                    const startDate = booster.start_date
                      ? moment.utc(booster.start_date).local()
                      : booster.start_date;
                    const endDate = booster.end_date
                      ? moment.utc(booster.end_date).local()
                      : booster.end_date;

                    return (
                      <>
                        <Col xs={2}>
                          <ISOStringDateTimePicker
                            label="Start Date"
                            name="start_date"
                            value={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(e) =>
                              handleEdit(
                                {
                                  ...e,
                                  target: {
                                    ...e.target,
                                    value: moment(e.target.value).utc(), // Format selected value to utc
                                  },
                                },
                                index
                              )
                            }
                            showTimeSelect
                            minDate={moment(
                              tournament.time_configuration.start
                            )}
                            maxDate={moment(tournament.time_configuration.end)}
                            disabled={
                              state.loading || state.saving || !state.editable
                            }
                          />
                        </Col>
                        <Col xs={2}>
                          <ISOStringDateTimePicker
                            label="End Date"
                            name="end_date"
                            value={endDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(e) =>
                              handleEdit(
                                {
                                  ...e,
                                  target: {
                                    ...e.target,
                                    value: moment(e.target.value).utc(), // Format selected value to utc
                                  },
                                },
                                index
                              )
                            }
                            showTimeSelect
                            minDate={moment(
                              tournament.time_configuration.start
                            )}
                            maxDate={moment(tournament.time_configuration.end)}
                            disabled={
                              state.loading || state.saving || !state.editable
                            }
                          />
                        </Col>
                      </>
                    );
                  })()}
                  <Col xs={2}>
                    <Select
                      label="Day of Week"
                      name={"day_of_week"}
                      value={booster.day_of_week}
                      options={daysOfWeek}
                      onChange={(e) =>
                        handleEdit(
                          {
                            ...e,
                            target: {
                              ...e.target,
                              value: +e.target.value, // Convert selected value to int
                            },
                          },
                          index
                        )
                      }
                      disabled={
                        state.loading || state.saving || !state.editable
                      }
                    />
                  </Col>
                  <Col xs={state.editable ? 1 : 2}>
                    <NumberInput
                      label="Day of month"
                      name={"day_of_month"}
                      value={booster.day_of_month}
                      onChange={(e) => handleEdit(e, index)}
                      min={1}
                      max={31}
                      disabled={
                        state.loading || state.saving || !state.editable
                      }
                    />
                  </Col>
                  {state.editable && (
                    <Col xs={1} style={{ paddingTop: "35px" }}>
                      <Button
                        disabled={
                          state.loading || state.saving || !state.editable
                        }
                        variant="outline-danger"
                        size="sm"
                        className="float-end"
                        onClick={() => handleDeletion(index)}
                      >
                        Delete
                      </Button>
                    </Col>
                  )}
                </Row>
              )
            );
          })}
        </>
      )}
    </div>
  );
};

export default Boosters;
