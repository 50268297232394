import { Card, Col, Row } from "react-bootstrap";
import NumberInput from "./NumberInput";
import { useCallback } from "react";

const TournamentDepositAmountIngestionConfiguration = ({
  config,
  onConfigChange,
  className,
  disabled,
  errors,
}) => {
  const handleEdit = useCallback(
    (e) => {
      if (typeof onConfigChange !== "function") return;

      let { name, value } = e.target;

      //
      // Reset the min_deposit_amount and max_deposit_amount to null
      // when the value is less than or equal to 0
      if (
        value <= 0 &&
        ["min_deposit_amount", "max_deposit_amount"].includes(name)
      ) {
        value = null;
      }

      onConfigChange({ target: { name, value } });
    },
    [onConfigChange]
  );

  return (
    <Card className={className}>
      <Card.Title className="p-3 mb-0 border-bottom">
        Deposit amount ingestion
      </Card.Title>
      <Card.Body>
        <Row className="mb-2">
          <Col xs={4}>
            <NumberInput
              label="Multiplication factor"
              name="deposit_amount_multiplication_factor"
              value={config?.deposit_amount_multiplication_factor}
              onChange={(e) => handleEdit(e)}
              min={0}
              disabled={disabled}
              errorMessages={errors?.deposit_amount_multiplication_factor}
            />
          </Col>

          <Col xs={4}>
            <NumberInput
              label="Min deposit amount"
              name="min_deposit_amount"
              value={config?.min_deposit_amount}
              onChange={(e) => handleEdit(e)}
              disabled={disabled}
              errorMessages={errors?.min_deposit_amount}
            />
          </Col>

          <Col xs={4}>
            <NumberInput
              label="Max deposit amount"
              name="max_deposit_amount"
              value={config?.max_deposit_amount}
              onChange={(e) => handleEdit(e)}
              min={config?.min_deposit_amount}
              disabled={disabled}
              errorMessages={errors?.max_deposit_amount}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TournamentDepositAmountIngestionConfiguration;
