import constants from "./constants";

/**
 * Get the display text for the specified win criteria.
 * @param {number} criteria - Win criteria value.
 * @returns .
 */
export const getWinCriteriaDisplayText = (criteria) => {
  switch (criteria) {
    case constants.WinCriteria.Turnover:
      return "Turnover";
    case constants.WinCriteria.NetWin:
      return "Net Win";
    case constants.WinCriteria.EqualizedWin:
      return "Equalized Win";
    case constants.WinCriteria.NumberRounds:
      return "Number of Rounds";
    default:
      return criteria;
  }
};

/**
 * Get the display text for the specified tournament status.
 * @param {number} status - Tournament status.
 * @returns .
 */
export const getTournamentStatusDisplayText = (status) => {
  switch (status) {
    case constants.Tournament.Status.Draft:
      return "Draft";
    case constants.Tournament.Status.InProgress:
      return "In Progress";
    case constants.Tournament.Status.Completed:
      return "Completed";
    case constants.Tournament.Status.Canceled:
      return "Canceled";
    default:
      return status;
  }
};

/**
 * Get the display text for the specified tournament opt-in condition.
 * @param {number} condition - Tournament opt-in condition.
 * @returns .
 */
export const getTournamentOptInConditionDisplayText = (condition) => {
  switch (condition) {
    case constants.Tournament.OptInCondition.Open:
      return "Open";
    case constants.Tournament.OptInCondition.OptIn:
      return "Opt-In Required";
    case constants.Tournament.OptInCondition.Invite:
      return "Invite Only";
    default:
      return condition;
  }
};

/**
 * Get the display text for the specified tournament score driver.
 * @param {number} driver - Tournament score driver.
 * @returns
 */
export const getTournamentScoreDriverDisplayText = (driver) => {
  switch (driver) {
    case constants.Tournament.ScoreDriver.GamingTransactions:
      return "Gaming Transactions";
    case constants.Tournament.ScoreDriver.BonusWinAmount:
      return "Bonus Win Amount";
    case constants.Tournament.ScoreDriver.DepositAmount:
      return "Deposit Amount";
    default:
      return driver;
  }
};

/**
 * Get the display text for the specified tournament bonus type.
 * @param {number} type - Tournament bonus type.
 * @returns .
 */
export const getTournamentBonusTypeDisplayText = (type) => {
  switch (type) {
    case constants.Tournament.BonusType.CasinoWagering:
      return "Casino Wagering";
    case constants.Tournament.BonusType.FreeSpins:
      return "Free Spins";
    case constants.Tournament.BonusType.BonusMoney:
      return "Bonus Money";
    case constants.Tournament.BonusType.Items:
      return "Items";
    case constants.Tournament.BonusType.FreeBet:
      return "Free Bet";
    default:
      return type;
  }
};

/**
 * Build URL by concatenating the provided parts.
 * @param  {...any} parts - URL parts to concatenate.
 * @returns .
 */
export const buildUrl = (...parts) =>
  parts.map((p) => p.replace(/^\/|\/$/g, "")).join("/");

/**
 * Check if the given object is an array with at least one element.
 * @param {*} object - The object to check.
 * @returns true if the given object is an array with at least one element, otherwise false.
 */
export const isArrayWithAtLeastOneElement = (object) =>
  Array.isArray(object) && object.length > 0;

/**
 * Get properties of the given object.
 * @param {*} object - The object to get the properties from.
 * @returns {Array} An array of objects with the properties of the given object.
 */
export const getObjectProps = (object) =>
  Object.keys(object).map((key) => {
    return {
      key: key,
      value: object[key],
    };
  });

/**
 * Set the property on the given object.
 * @param {*} object - The object to set the property on.
 * @param {string} name - Property name.
 * @param {*} value - Value.
 */
export const setProp = (object, name, value) => {
  const parts = name.split(".");

  let target = object;
  for (let i = 0; i < parts.length - 1; i++) {
    target = target[parts[i]] ??= {};
  }

  target[parts[parts.length - 1]] = value;
};

/**
 * Get the property of the given object.
 * @param {*} object - The object to get the property from.
 * @param {string} name - Property name.
 */
export const getProp = (object, name) => {
  const parts = name.split(".");

  let target = object;
  for (let i = 0; i < parts.length; i++) {
    if (isNullOrUndefined(target)) return target;
    target = target[parts[i]];
  }

  return target;
};

/**
 * Check if the given value is null or undefined.
 * @param {*} value - The value to check.
 * @returns .
 */
export const isNullOrUndefined = (value) =>
  value === null || value === undefined;

/**
 * Build query string from the given parameters.
 * @param {*} params - Parameters.
 * @returns .
 */
export const buildQueryString = (params) => {
  const encode = encodeURIComponent;

  const queryString = getObjectProps(params)
    .filter(({ value }) => !isNullOrUndefined(value)) // filter out null or undefined values
    .map(({ key, value }) => encode(key) + "=" + encode(value))
    .join("&");

  return queryString.length > 0 ? `?${queryString}` : "";
};

/**
 * Check if the given string is null, empty or whitespace.
 * @param {string} string - The string to check.
 * @returns .
 */
export const isNullOrWhiteSpace = (string) => {
  return !string || string.length === 0 || string?.trim() === "";
};

/**
 * Check if the given image source is accessible.
 * @param {string} src - Image source.
 * @returns *
 */
export const isImageAccessible = (src) => {
  return new Promise((resolve) => {
    // empty sources are considered as not accessible
    if (isNullOrWhiteSpace(src)) {
      resolve(false);
      return;
    }

    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = src;
  });
};

/**
 * Generate slug from the given string.
 * @param {string} str - String to generate slug from.
 * @returns *
 */
export const generateSlug = (str) => {
  return str
    ?.trim()
    .toLowerCase()
    .replace(/[^\w\s]/gi, "")
    .replace(/\s+/g, "-");
};

/**
 * Get the display text for the specified tournament bonus.
 * @param {*} bonus - Tournament bonus.
 * @returns .
 */
export const getTournamentBonusDisplayText = (bonus) => {
  return (
    bonus.nominal_name ||
    bonus.name ||
    (() => {
      switch (bonus.type) {
        case constants.Tournament.BonusType.FreeSpins:
          return `${bonus.value} FS`;
        case constants.Tournament.BonusType.BonusMoney:
          return `${formatNumber(bonus.value)} ${constants.DefaultCurrency}`;
        case constants.Tournament.BonusType.FreeBet:
          return `${bonus.value} Free Bet`;
        default:
          return `${bonus.code}`;
      }
    })()
  );
};

/**
 * Format the given number.
 * @param {number} number - The number to format.
 * @returns .
 */
export const formatNumber = (number) => {
  const parts = number.toString().split(".");

  //
  // The string will be built in reverse order.

  const builder = [];

  //
  // Try append the decimal part.

  const decimalPart = parts[1];

  if (decimalPart) {
    for (let i = decimalPart.length - 1; i >= 0; i--) {
      builder.push(decimalPart[i]);
    }

    builder.push(",");
  }

  //
  // Try append the integer part.

  const intPart = parts[0];

  if (intPart) {
    for (let i = intPart.length - 1; i >= 0; i--) {
      if (i < intPart.length - 1 && (intPart.length - i) % 3 === 1) {
        builder.push(".");
      }

      builder.push(intPart[i]);
    }
  }

  return builder.reverse().join("");
};

/**
 * Check if the given string is null or empty.
 * @param {string} string - The string to check.
 * @returns .
 */
export const isNullOrEmpty = (string) =>
  string === null || string === undefined || string === "";

/**
 * Download the given blob.
 * @param {*} blob - Blob to download.
 * @param {*} filename - File name.
 */
export const downloadBlob = (blob, filename) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
