import { Card, Col, Form, Row, Table, Button } from "react-bootstrap";
import Input from "./Input";
import TournamentTimeConfiguration from "./TournamentTimeConfiguration";
import Select from "./Select";
import TournamentWageringConditionList from "./TournamentWageringConditionList";
import {
  opt_in_condition_options,
  score_driver_options,
  win_criteria_options,
} from "../utils/options";
import ImageSourceInput from "./ImageSourceInput";
import { generateSlug, isNullOrWhiteSpace } from "../utils/helpers";
import Textarea from "./Textarea";
import Check from "./Check";
import TournamentTierList from "./TournamentTierList";
import RichTextarea from "./RichTextarea";
import constants from "../utils/constants";
import TournamentBonusWinAmountIngestionConfiguration from "./TournamentBonusWinAmountIngestionConfiguration";
import TournamentDepositAmountIngestionConfiguration from "./TournamentDepositAmountIngestionConfiguration";
import TournamentUserRoleAssignmentScoreConfiguration from "./TournamentUserRoleAssignmentScoreConfiguration";

const Tournament = ({
  className,
  tournament,
  onTournamentChange,
  onTournamentSummaryVideoUrlChange,
  onTournamentSummaryVideoUrlSave,
  disabled,
  errors,
}) => {
  return (
    <Row className={className}>
      <Col>
        <Card className="mb-4">
          <Card.Title className="d-flex p-3 mb-0 border-bottom">
            General
          </Card.Title>

          <Card.Body>
            <Row>
              <Select
                className="mb-3 col-6"
                label="Score driver"
                name="score_driver"
                value={tournament.score_driver}
                valueIsNumber
                onChange={onTournamentChange}
                options={score_driver_options}
                disabled={disabled}
              />
            </Row>

            <Row>
              <div className="mb-3 col-6">
                <Check
                  label="Floor score"
                  name="floor_score"
                  value={tournament.floor_score}
                  onChange={onTournamentChange}
                  disabled={disabled}
                />
              </div>
            </Row>

            <Input
              className="mb-3"
              label="Title"
              type="text"
              name="title"
              value={tournament.title}
              onChange={onTournamentChange}
              placeholder="Enter tournament title..."
              disabled={disabled}
              errorMessages={errors?.title}
            />

            <Row>
              <Select
                className="mb-3 col-6"
                label="Opt-In condition"
                name="opt_in_condition"
                value={tournament.opt_in_condition}
                valueIsNumber
                onChange={onTournamentChange}
                options={opt_in_condition_options}
                disabled={disabled}
              />

              {tournament.score_driver ===
                constants.Tournament.ScoreDriver.GamingTransactions && (
                <Select
                  className="mb-3 col-6"
                  label="Win criteria"
                  name="win_criteria"
                  value={tournament.win_criteria}
                  valueIsNumber
                  onChange={onTournamentChange}
                  options={win_criteria_options}
                  disabled={disabled}
                />
              )}
            </Row>

            <div className="border-top pt-3 mt-3 d-flex gap-3">
              <Check
                label="Promote"
                name="is_promoted"
                value={tournament.is_promoted}
                onChange={onTournamentChange}
                disabled={disabled}
              />
              <Check
                className=""
                label="Raffle"
                name="is_raffle"
                value={tournament.is_raffle}
                onChange={onTournamentChange}
                disabled={disabled}
              />
            </div>
          </Card.Body>
        </Card>

        <TournamentTimeConfiguration
          className="mb-4"
          name="time_configuration"
          config={tournament.time_configuration}
          onConfigChange={onTournamentChange}
          disabled={disabled}
          errors={errors}
        />

        <Card className="mb-4">
          <Card.Title className="d-flex p-3 mb-0 border-bottom">
            Images
          </Card.Title>

          <Card.Body>
            <Row>
              <Col className="mb-3">
                <ImageSourceInput
                  label="Desktop"
                  name="hero_image_desktop"
                  value={tournament.hero_image_desktop}
                  onChange={onTournamentChange}
                  placeholder="..."
                  disabled={disabled}
                  errorMessages={errors?.hero_image_desktop}
                />
              </Col>

              <Col className="mb-3">
                <ImageSourceInput
                  label="Mobile"
                  name="hero_image_mobile"
                  value={tournament.hero_image_mobile}
                  onChange={onTournamentChange}
                  placeholder="..."
                  disabled={disabled}
                  errorMessages={errors?.hero_image_mobile}
                />
              </Col>

              <Col className="mb-3">
                <ImageSourceInput
                  label="List"
                  name="list_image"
                  value={tournament.list_image}
                  onChange={onTournamentChange}
                  placeholder="..."
                  disabled={disabled}
                  errorMessages={errors?.list_image}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Title className="d-flex p-3 mb-0 border-bottom">
            Technical
          </Card.Title>

          <Card.Body>
            <Row>
              <Col md={8} className="mb-3">
                <Input
                  className="mb-3"
                  label="Slug"
                  type="text"
                  name="slug"
                  value={tournament.slug}
                  onChange={onTournamentChange}
                  placeholder={
                    !isNullOrWhiteSpace(tournament.title)
                      ? generateSlug(tournament.title)
                      : ""
                  }
                  disabled={disabled}
                />
                <Form.Text>
                  If left empty, slug will be generated from the title.
                </Form.Text>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Input
                  label="Summary video URL"
                  type="text"
                  name="summary_video_url"
                  value={tournament.summary_video_url}
                  onChange={onTournamentSummaryVideoUrlChange}
                />
              </Col>
              {tournament.status !== constants.Tournament.Status.Draft && (
                <Col md={4} className="d-flex align-items-end">
                  <Button
                    size="md"
                    variant="outline-primary"
                    className="me-2"
                    onClick={onTournamentSummaryVideoUrlSave}
                  >
                    Save
                  </Button>
                </Col>
              )}
            </Row>

            <div className="border-top pt-3 mt-3">
              <Check
                label="Hide from public"
                name="is_hidden"
                value={tournament.is_hidden}
                onChange={onTournamentChange}
                disabled={disabled}
              />
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Title className="d-flex p-3 mb-0 border-bottom">
            Content
          </Card.Title>

          <Card.Body>
            <Input
              className="mb-3 col-6"
              label="CTA text"
              type="text"
              name="cta_text"
              value={tournament.cta_text}
              onChange={onTournamentChange}
              placeholder="e.g. Join now"
              disabled={disabled}
              errorMessages={errors?.cta_text}
            />

            <Textarea
              className="mb-2"
              label="Description"
              name="description"
              value={tournament.description}
              onChange={onTournamentChange}
              disabled={disabled}
              height={150}
            />
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Title className="d-flex p-3 mb-0 border-bottom">
            Terms and conditions
          </Card.Title>

          <Card.Body>
            <Input
              className="mb-3 col-6"
              label="Url"
              type="text"
              name="terms_and_conditions"
              value={tournament.terms_and_conditions}
              onChange={onTournamentChange}
              placeholder="http://..."
              disabled={disabled}
              errorMessages={errors?.terms_and_conditions}
            />

            <div className="mb-3">
              <Form.Text>
                The expected UI behavior is to display the text when the URL is
                empty.
              </Form.Text>
            </div>

            <RichTextarea
              className="mb-2"
              label="Text"
              name="terms_and_conditions_text"
              value={tournament.terms_and_conditions_text}
              onChange={onTournamentChange}
              disabled={disabled}
              errorMessages={errors?.terms_and_conditions_text}
            />
          </Card.Body>
        </Card>
        <TournamentUserRoleAssignmentScoreConfiguration
          className="mb-4"
          name="user_role_assignment_score_configuration"
          config={tournament.user_role_assignment_score_configuration}
          onConfigChange={onTournamentChange}
          disabled={disabled}
          errors={errors}
        />
      </Col>

      <Col lg={6}>
        {tournament.score_driver ===
          constants.Tournament.ScoreDriver.GamingTransactions && (
          <TournamentWageringConditionList
            className="mb-4"
            name="wagering_conditions"
            wageringConditions={tournament.wagering_conditions}
            onWageringConditionsChange={onTournamentChange}
            disabled={disabled}
            errors={errors}
          />
        )}

        {tournament.score_driver ===
          constants.Tournament.ScoreDriver.BonusWinAmount && (
          <TournamentBonusWinAmountIngestionConfiguration
            className="mb-4"
            name="bonus_win_amount_ingestion_configuration"
            config={tournament.bonus_win_amount_ingestion_configuration}
            onConfigChange={onTournamentChange}
            disabled={disabled}
            errors={errors}
          />
        )}

        {tournament.score_driver ===
          constants.Tournament.ScoreDriver.DepositAmount && (
          <TournamentDepositAmountIngestionConfiguration
            className="mb-4"
            config={tournament}
            onConfigChange={onTournamentChange}
            disabled={disabled}
            errors={errors}
          />
        )}

        <Card className="mb-4">
          <Card.Title className="d-flex p-3 mb-0 border-bottom">
            Prizes
          </Card.Title>

          <Card.Body>
            <Row>
              <Input
                className="mb-3 col-6"
                label="Award prizes"
                type="text"
                name="award_prizes"
                value={tournament.award_prizes}
                onChange={onTournamentChange}
                disabled={disabled}
              />

              <Input
                className="mb-3 col-6"
                label="Total item prizes"
                type="text"
                name="total_item_prizes"
                value={tournament.total_item_prizes}
                onChange={onTournamentChange}
                disabled={disabled}
              />
            </Row>

            <Row className="mb-3">
              <Col xs={6}>
                <Table>
                  <thead>
                    <tr>
                      <th colSpan={2}>Totals</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Free spins</td>
                      <td>{tournament.free_spins}</td>
                    </tr>
                    <tr>
                      <td>Bonus money</td>
                      <td>{tournament.total_pool_prize || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <TournamentTierList
          className="mb-4"
          name="tiers"
          tiers={tournament.tiers}
          onTiersChange={onTournamentChange}
          disabled={disabled}
          errors={errors}
        />
      </Col>
    </Row>
  );
};

export default Tournament;
