import moment from "moment";
import constants from "./constants";
import {
  formatNumber,
  generateSlug,
  getProp,
  isNullOrWhiteSpace,
  setProp,
} from "./helpers";
import { time_configuration_days } from "../components/TournamentTimeConfiguration";

/**
 * Creates a new tournament model.
 * @returns .
 */
export const createTournamentModel = () => {
  const start = moment.utc().minute(0).millisecond(0).add(1, "hours");
  const end = start.clone().add(1, "days");

  return {
    id: 0,
    slug: "",
    status: constants.Tournament.Status.Draft,
    score_driver: constants.Tournament.ScoreDriver.GamingTransactions,
    win_criteria: constants.WinCriteria.Turnover,
    hero_image_desktop: "",
    hero_image_mobile: "",
    list_image: "",
    opt_in_condition: constants.Tournament.OptInCondition.OptIn,
    title: "",
    description: "",
    cta_text: "",
    terms_and_conditions: "",
    terms_and_conditions_text: "",
    award_prizes: "",
    free_spins: 0,
    total_pool_prize: "",
    total_item_prizes: "",
    is_promoted: false,
    is_obsolete: false,
    is_hidden: false,
    tiers: [],
    wagering_conditions: [],
    time_configuration: {
      start: start.toISOString(),
      end: end.toISOString(),
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    bonus_win_amount_ingestion_configuration: {},
    deposit_amount_multiplication_factor: 1,
    min_deposit_amount: null,
    max_deposit_amount: null,
    floor_score: false,
  };
};

/**
 * Creates a new tournament wagering condition model.
 * @returns .
 */
export const createTournamentWageringConditionModel = () => {
  return {
    platform: "both",
    min_stake: 1,
    max_stake: null,
    contribution: 100,
    casino_game_ids: [],
  };
};

/**
 * Creates a new tournament tier model.
 * @param {number} rank - The rank of the tier.
 * @returns .
 */
export const createTournamentTierModel = (rank) => {
  return {
    rank_min: rank,
    rank_max: rank,
    bonus: {
      type: 2,
      code: "",
      name: "",
      nominal_name: "",
      value: 0,
    },
  };
};

/**
 * Perform event changes on the tournament model.
 * @param {*} model - The tournament model.
 * @param {*} e - Event object.
 * @returns .
 */
export const performTournamentModelChange = (model, e) => {
  const { name, value } = e.target;

  if (name.startsWith("tiers.")) {
    //
    // keep the rank_max no less than the rank_min
    if (name.endsWith(".rank_min")) {
      const tier = getProp(model, name.substring(0, name.lastIndexOf(".")));
      if (value > tier.rank_max) {
        setProp(tier, "rank_max", value);
      }
    }

    //
    // reset the bonus value to 0 when the type changes
    if (name.endsWith("bonus.type")) {
      const bonus = getProp(model, name.substring(0, name.lastIndexOf(".")));
      setProp(bonus, "value", 0);
    }

    setProp(model, name, value);

    //
    // Handle prize totals.
    // Aggregate the total bonus money and free spins from all tiers.
    const totals = { bonusMoney: 0, freeSpins: 0 };

    for (const tier of model.tiers) {
      if (tier.rank_max < tier.rank_min) continue;

      const n = tier.rank_max - tier.rank_min + 1;
      const v = tier.bonus.value ?? 0;

      switch (tier.bonus.type) {
        case constants.Tournament.BonusType.BonusMoney:
          totals.bonusMoney += v * n;
          break;

        case constants.Tournament.BonusType.FreeSpins:
          totals.freeSpins += v * n;
          break;

        default:
          break;
      }
    }

    setProp(
      model,
      "total_pool_prize",
      `${formatNumber(totals.bonusMoney)} ${constants.DefaultCurrency}`
    );

    setProp(model, "free_spins", totals.freeSpins);

    return;
  }

  if (name === "time_configuration.day") {
    const day = parseInt(e.target.day);

    //
    // determine and update the exact day property
    const dayProp = `time_configuration.${time_configuration_days[day]}`;
    if (!isNaN(day)) {
      setProp(model, dayProp, e.target.checked);
    }

    return;
  }

  if (name === "time_configuration.start") {
    setProp(model, name, value);

    //
    // keep the end time no less than the start time
    if (model.time_configuration.end < model.time_configuration.start) {
      setProp(model, "time_configuration.end", value);
    }

    return;
  }

  setProp(model, name, value);
};

/**
 * Prepare tournament model to be sent to the API.
 * @param {*} model - The tournament model.
 * @returns .
 */
export const prepareTournamentModelForApi = (model) => {
  //
  // ensure slug generated
  if (isNullOrWhiteSpace(model.slug)) model.slug = generateSlug(model.title);
};
