import Keycloak from "keycloak-js";
import { buildUrl } from "./helpers";
import { upsertUserAsync } from "./api";

const kc = new Keycloak({
  /**
   * The URL of the Keycloak server.
   */
  url: window.EnvConstants.REACT_APP_KEYCLOAK_URL,

  /**
   * The realm this Keycloak client should authenticate within.
   */
  realm: window.EnvConstants.REACT_APP_KEYCLOAK_REALM,

  /**
   * The client ID of the Keycloak client.
   */
  clientId: window.EnvConstants.REACT_APP_KEYCLOAK_CLIENT_ID,
});

/**
 * Get the user information.
 * @returns .
 */
export function getUser() {
  const authenticated = kc.authenticated;
  if (!authenticated) {
    return { authenticated };
  }

  const id = kc.idTokenParsed.sub;
  const emailVerified = kc.idTokenParsed.email_verified;
  const email = kc.idTokenParsed.email;
  const displayName = kc.idTokenParsed.name;
  const roles = kc.resourceAccess[kc.clientId]?.roles.map((role) => role) ?? [];
  const accountPageUrl = buildUrl(kc.idTokenParsed.iss, "/account/#/");

  return {
    authenticated,
    id,
    emailVerified,
    email,
    displayName,
    roles,
    accountPageUrl,
  };
}

/**
 * Get user authentication token.
 * @returns .
 */
export function getUserToken() {
  return kc.token;
}

/**
 * Log out the user.
 * @returns .
 */
export async function logOutAsync() {
  return await kc.logout();
}

/**
 * Authenticate the user.
 * @returns .
 */
export async function authenticateAsync() {
  const authenticated = await kc.init({ onLoad: "login-required" });
  if (!authenticated) return false;

  //
  // This fire-and-forget is intentional. We don't want to block the user from
  // accessing the app while we're updating the user information.
  upsertUserAsync();

  const tokenParsed = kc.tokenParsed;
  const exp = tokenParsed?.exp;
  const now = Date.now();

  const expiresIn = (exp - now / 1000 + kc.timeSkew) * 1000;

  console.log("Token expires in", expiresIn, "ms");
  
  kc.onTokenExpired = async () => {
    console.log("Token expired. Refreshing token...");
    await kc.updateToken();
  };

  kc.tokenTimeoutHandle = setTimeout(kc.onTokenExpired, expiresIn);

  return true;
}
