import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "./Select";
import Input from "./Input";
import NumberInput from "./NumberInput";
import { createTournamentTierModel } from "../utils/models";
import { bonus_type_options } from "../utils/options";
import { isNullOrWhiteSpace } from "../utils/helpers";
import { ExclamationCircleFill, TrophyFill } from "react-bootstrap-icons";
import { getBonusesAsync } from "../utils/api";
import BonusCodeInput from "./BonusCodeInput";
import constants from "../utils/constants";

const tier_bonus_type_with_value = [
  constants.Tournament.BonusType.FreeSpins,
  constants.Tournament.BonusType.BonusMoney,
  constants.Tournament.BonusType.FreeBet,
];

const TournamentTierList = ({
  name,
  tiers,
  onTiersChange,
  className,
  disabled,
  errors,
}) => {
  const [state, setState] = useState({
    loading: false,
    bonuses: [],
    error: null,
  });

  const handleAdd = () => {
    if (typeof onTiersChange !== "function") return;

    const ranks = tiers.reduce((a, t) => [...a, t.rank_min, t.rank_max], []);
    const nextRank = Math.max(...[0, ...ranks]) + 1;
    const newTier = createTournamentTierModel(nextRank);

    onTiersChange({
      target: { name, value: [...tiers, newTier] },
    });
  };

  const handleRemoveEmpty = () => {
    if (typeof onTiersChange !== "function") return;

    onTiersChange({
      target: {
        name,
        value: [...tiers.filter((t) => t && !isNullOrWhiteSpace(t.bonus.code))],
      },
    });
  };

  const handleDeletion = (index) => {
    if (typeof onTiersChange !== "function") return;

    onTiersChange({
      target: { name, value: tiers.filter((_, i) => i !== index) },
    });
  };

  const fetchBonuses = async (ct) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    try {
      const bonuses = await getBonusesAsync(ct);

      setState((prev) => ({
        ...prev,
        loading: false,
        bonuses,
        error: null,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: { ...error, message: "Can't get bonuses: " + error.message },
      }));
    }
  };

  useEffect(() => {
    const cts = new AbortController();

    setState((prev) => ({
      ...prev,
      loading: false,
      bonuses: [],
      error: null,
    }));

    fetchBonuses(cts.signal);

    return () => {
      cts.abort();
    };
  }, []);

  return (
    <Card className={className}>
      <Card.Title className="d-flex p-3 mb-0 border-bottom">
        <div className="flex-grow-1">Tiers</div>

        {state.loading && (
          <div style={{ minWidth: 20 }}>
            <Spinner size="sm" />
          </div>
        )}
      </Card.Title>

      {state.error && (
        <Card.Body className="border-bottom">
          <Alert variant="danger" className="mb-0">
            {state.error.message}
          </Alert>
        </Card.Body>
      )}

      {errors?.[`${name}`] && (
        <div className="d-none form-control is-invalid" />
      )}

      {tiers.length === 0 ? (
        <Card.Body>
          <div className="text-muted">There are no tiers</div>
        </Card.Body>
      ) : (
        <div className="tiers">
          {tiers.map((tier, index) => (
            <Card.Body key={index} className={index > 0 ? "border-top" : ""}>
              <div className="py-3 mb-3 border-bottom">
                <TierTitle tier={tier} />

                {index === tiers.length - 1 && (
                  <Button
                    disabled={disabled}
                    variant="outline-danger"
                    size="sm"
                    className="float-end"
                    onClick={() => handleDeletion(index)}
                  >
                    Delete
                  </Button>
                )}
              </div>

              <Row>
                <Col sm={6}>
                  <Row>
                    <Col>
                      <NumberInput
                        className="mb-3"
                        label="Min rank"
                        name={`${name}.${index}.rank_min`}
                        value={tier.rank_min}
                        min={1}
                        onChange={onTiersChange}
                        disabled={disabled}
                        errorMessages={errors?.[`${name}.${index}.rank_min`]}
                      />
                    </Col>
                    <Col>
                      <NumberInput
                        className="mb-3"
                        label="Max rank"
                        name={`${name}.${index}.rank_max`}
                        value={tier.rank_max}
                        min={tier.rank_min}
                        onChange={onTiersChange}
                        disabled={disabled}
                        errorMessages={errors?.[`${name}.${index}.rank_max`]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="border-bottom mb-2 pb-2">Bonus:</div>

              <Row xxl={4} lg={2} md={4} sm={2} xs={1}>
                <Col className="mb-3">
                  <BonusCodeInput
                    label="Code"
                    name={`${name}.${index}.bonus`}
                    value={tier.bonus.code}
                    onChange={onTiersChange}
                    placeholder="..."
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${index}.bonus.code`]}
                    bonuses={state.bonuses}
                  />
                </Col>

                <Col className="mb-3">
                  <Input
                    label="Name"
                    name={`${name}.${index}.bonus.name`}
                    value={tier.bonus.name}
                    onChange={onTiersChange}
                    placeholder="..."
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${index}.bonus.name`]}
                  />
                </Col>

                <Col className="mb-3">
                  <Input
                    label="Nominal name"
                    name={`${name}.${index}.bonus.nominal_name`}
                    value={tier.bonus.nominal_name}
                    onChange={onTiersChange}
                    placeholder="..."
                    disabled={disabled}
                    errorMessages={
                      errors?.[`${name}.${index}.bonus.nominal_name`]
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <Select
                    label="Type"
                    name={`${name}.${index}.bonus.type`}
                    value={tier.bonus.type}
                    valueIsNumber
                    onChange={onTiersChange}
                    options={bonus_type_options}
                    disabled={disabled}
                  />
                </Col>

                <Col className="mb-3">
                  <Input
                    label="ImageUrl"
                    name={`${name}.${index}.bonus.image_url`}
                    value={tier.bonus.image_url}
                    onChange={onTiersChange}
                    placeholder="..."
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${index}.bonus.image_url`]}
                  />
                </Col>

                {tier_bonus_type_with_value.includes(tier.bonus.type) && (
                  <Col className="mb-3">
                    <NumberInput
                      label="Value"
                      name={`${name}.${index}.bonus.value`}
                      value={tier.bonus.value}
                      onChange={onTiersChange}
                      disabled={disabled}
                    />
                    <Form.Text className="text-muted">
                      Total:{" "}
                      {(tier.rank_max - tier.rank_min + 1) *
                        (tier.bonus.value ?? 0)}
                    </Form.Text>
                  </Col>
                )}
              </Row>
            </Card.Body>
          ))}
        </div>
      )}

      <Card.Header className="py-3 border-bottom-0 border-top">
        <Button
          disabled={disabled}
          size="sm"
          variant="outline-primary"
          className="me-2"
          onClick={handleAdd}
        >
          Add tier
        </Button>

        <Button
          disabled={disabled}
          size="sm"
          variant="outline-primary"
          className="me-2"
          onClick={handleRemoveEmpty}
        >
          Remove empty
        </Button>

        {tiers.length > 0 && (
          <div className="float-end">{tiers.length} tiers</div>
        )}
      </Card.Header>
    </Card>
  );
};

const TierTitle = ({ tier }) => {
  let body = null;

  if (tier.rank_min > 0 && tier.rank_min === tier.rank_max) {
    body = (
      <React.Fragment>
        <TrophyFill className="me-2" size={16} />
        <div>{tier.rank_min}</div>
      </React.Fragment>
    );
  } else if (tier.rank_min <= tier.rank_max) {
    body = (
      <React.Fragment>
        <TrophyFill className="me-2" size={16} />
        <div>
          {tier.rank_min} → {tier.rank_max}
        </div>
      </React.Fragment>
    );
  } else {
    body = (
      <React.Fragment>
        <ExclamationCircleFill className="me-2 text-danger" size={16} />
        <div className="text-danger">Invalid rank range</div>
      </React.Fragment>
    );
  }

  return (
    <div className="d-inline-block">
      <div className="d-flex align-items-center">{body}</div>
    </div>
  );
};

export default TournamentTierList;
